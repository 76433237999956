import * as React from "react";
import SEO from "../../components/seo";

import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../../components/ContactUs";
import MoreProjects from "../../components/MoreProjects";
import { useState } from "react";
import { useEffect } from "react";

const CoutrePage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <div className="">
        <SEO title="Coutre - Spring" lang="en" />

        <div className="hidden md:block w-full -mt-24 left-0 -mb-0 right-0 h-[34rem] relative ">
          <div draggable="false" className="w-full h-full absolute bg-black" />
          <div className="h-full w-full flex justify-center items-center absolute bottom-0 overflow-hidden">
            <StaticImage
              draggable="false"
              className="-bottom-1/3 object-contain max-w-5xl "
              src="../../images/projects/coutre/header.png"
              alt="Drift App"
              placeholder="blurred"
            />
          </div>
        </div>

        <div className="max-w-7xl px-6 lg:px-8 mx-auto  text-black100 font-inter overflow-x-hidden">
          <div className="w-full font-inter mt-9 mb-20 md:my-20 text-left xl:mx-32">
            <h1 className="text-4xl md:text-4xl lg:text-5xl font-semibold ">
              Coutre is a London-based interior design{" "}
              <br className="hidden lg:block" /> studio, with a focus on luxury
              & elegance, <br className="hidden lg:block" /> and a design to
              match.
            </h1>
            <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-52 md:mt-8 mt-6">
              <p className="md:w-1/2 max-w-[30rem] md:text-base">
                Interior design in the modern world is all about harmony,
                balance & style, and the Coutre website & brand book needed to
                reflect this. With a focus on imagery, clean lines & a flexible
                grid system, the result is a design that feels fresh, modern
                with a touch of elegance. Designed with responsiveness in mind,
                the website looks excellent on any device.
              </p>
              <p className="md:text-base ">
                - UI Design <br />- UX Design <br />- Visual Identity <br />-
                Deck Design
              </p>
            </div>
          </div>
          {/* IMAGES */}
          <div className="flex flex-col space-y-8">
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/1m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/1d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o1m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>

            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o2m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o3m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o4m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o1d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>

            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/2o2d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>

            <div className="w-full block md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/3m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/3d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full  md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/4o1m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full  md:hidden">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/4o2m.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
            <div className="w-full hidden md:block">
              {" "}
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/coutre/4d.png"
                alt="Home Page Web Design"
                placeholder="blurred"
              />
            </div>
          </div>
          <MoreProjects excludeProject="coutre" />
          <ContactUs />
        </div>
      </div>
    );
  }
};

export default CoutrePage;
